import { createAction, props } from '@ngrx/store'
import {
  BreakDownEpic,
  BreakDownEpicOld,
  BreakDownEpicStructured,
  BreakDownFeature,
  BreakDownRequest,
  FindLeadingIndicatorsRequest,
  GenerateAcceptanceCriteriaRequest,
  GenerateFeatureAcceptanceCriteriaRequest,
  ImproveRequest,
  ImproveResponse
} from './open-ai.model'

export const loadOpenAIs = createAction('[OpenAI] Load OpenAIs')

export const improveEpic = createAction(
  '[OpenAI] Improve Epic',
  props<{ data: ImproveRequest }>()
)

export const generateAcceptanceCriteria = createAction(
  '[OpenAI] Generate acceptance Criteria',
  props<{ data: GenerateAcceptanceCriteriaRequest }>()
)

export const generateFeatureAcceptanceCriteria = createAction(
  '[OpenAI] Generate Feature acceptance Criteria',
  props<{ data: GenerateFeatureAcceptanceCriteriaRequest }>()
)

export const findLeadingIndicators = createAction(
  '[OpenAI] Find Leading Indicators',
  props<{ data: FindLeadingIndicatorsRequest }>()
)

export const findLeadingIndicatorsSuccess = createAction(
  '[OpenAI] Find Leading Indicators Success',
  props<{ data: ImproveResponse }>()
)

export const improveEpicSuccess = createAction(
  '[OpenAI] Improve Epic Success',
  props<{ data: ImproveResponse }>()
)
export const generateAcceptanceCriteriaSuccess = createAction(
  '[OpenAI] Generate Acceptance Criteria Success',
  props<{ data: ImproveResponse }>()
)

export const loadOpenAIsSuccess = createAction(
  '[OpenAI] Load OpenAIs Success',
  props<{ data: any }>()
)

export const loadOpenAIsFailure = createAction(
  '[OpenAI] Load OpenAIs Failure',
  props<{ error: any }>()
)

export const breakDownEpic = createAction(
  '[OpenAI] Break DownEpic',
  props<{ data: ImproveRequest }>()
)
// Action to trigger fetching structured features from the backend
export const fetchStructuredEpic = createAction(
  '[Epic] Fetch Structured Epic',
  props<{ data: ImproveRequest }>()
)

// Success action for when the structured data is successfully fetched
export const fetchStructuredEpicSuccess = createAction(
  '[Epic] Fetch Structured Epic Success',
  props<{ data: BreakDownEpicStructured }>()
)

export const breakDownFeature = createAction(
  '[OpenAI] Break Down Feature',
  props<{ data: BreakDownRequest }>()
)
export const breakDownFeatureStructured = createAction(
  '[OpenAI] Break Down Feature Structured',
  props<{ data: BreakDownRequest }>()
)

export const breakDownEpicSuccess = createAction(
  '[OpenAI] Break DownEpic Success',
  props<{ data: BreakDownEpicOld }>()
)

export const breakDownFeatureSuccess = createAction(
  '[OpenAI] Break Feature Success',
  props<{ data: BreakDownFeature }>()
)
export const breakDownFeatureStructuredSuccess = createAction(
  '[OpenAI] Break Feature Structured Success',
  props<{ data: BreakDownFeature }>()
)
