import { createFeatureSelector, createSelector } from '@ngrx/store'
import { LinkedSolution, Solution } from './solutions.model'
import { AppState } from '../../../core/core.module'
import { FEATURE_NAME, solutionAdapter } from './solutions.reducer'

import {
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectCurrentRoute
} from '../../../core/core.state'

import { Dictionary, EntityState } from '@ngrx/entity'
import { FeaturesState, selectFeatures } from '../../features.state'
import { Item } from '../../requirements-store/store/requirements.model'

export interface State1 extends AppState {
  solutions: SolutionState
}
/* export const selectAllSolutions = createSelector(
  selectExamples, entities=>{
    const allSolutions= entities;
  return allSolutions;
  });

  */
export interface SolutionState extends EntityState<Solution> {
  selectedSolutionId: string | null
}

export const selectSolutionsState = createSelector(
  selectFeatures,
  (state: FeaturesState) => state.solutions
)
/*
export const selectSolutions = createFeatureSelector<State1, SolutionState>(
  FEATURE_NAME
)
*/

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = solutionAdapter.getSelectors(selectSolutionsState)

export const selectSolutionsArray = createSelector(selectEntities, (entities) =>
  Object.values(entities)
)
/**
 *  @deprecated this method should not be used
 */
export const getSolutionById = createSelector(
  selectEntities,
  (entities, props) => entities[props.id]
)

export const getSolutionByIdF = (id) =>
  createSelector(selectEntities, (entities) => entities[id])
export const selectedSolution = (state: SolutionState) =>
  state.selectedSolutionId

// Selector to find related solutions for a given solution
export const selectRelatedSolutions = (solutionId: string) =>
  createSelector(
    selectAll,
    getSolutionByIdF(solutionId),
    (solutions: Solution[], targetSolution: Solution | undefined) => {
      if (!targetSolution || !targetSolution.relations) {
        return []
      }

      return targetSolution.relations
        .map((relation) =>
          solutions.find((sol) => sol.id === relation.relatedSolutionId)
        )
        .filter(
          (relatedSolution): relatedSolution is Solution => !!relatedSolution
        ) // Filter out undefined
    }
  )
export const getSelectedSolutionIdFromStore2 = createSelector(
  selectSolutionsState,
  (state: SolutionState) =>
    // console.log("aqui");
    state.selectedSolutionId
)
export const getSelectedSolutionIdFromStore = createSelector(
  selectSolutionsState,
  (state: SolutionState) => {
    // console.log(state.selectedSolutionId);
    // console.log(state.entities);
    /* const of1 = Object.assign(
      {},
      state.entities[state.selectedSolutionId]
    ) as Solution;
    return of1;
    */
    const of1: Solution = { ...state.entities[state.selectedSolutionId] }
    return of1
    // console.log('soluton ', of1);

    // return <Solution>Object.assign({},state.entities[state.selectedSolutionId])
  }
)
export const getSelectedSolutionId2 = createSelector(
  selectSolutionsState,
  (state: SolutionState) => state.entities[state.selectedSolutionId]
)

export const getSolutionByPropertyValue = createSelector(
  selectAll,
  (
    entities: Solution[],
    { columnId, columnValue }: { columnId: string; columnValue: string }
  ) =>
    entities
      .filter((item) => item[columnId] === columnValue)
      .sort((a, b) => {
        const nameA = a.customerType.toLowerCase(),
          nameB = b.customerType.toLowerCase()
        if (nameA < nameB)
          // sort string ascending
          return -1
        if (nameA > nameB) return 1
        return 0
      })
  // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
  // return filteredEntities;
)
export const getSolutionsByPortfolioId = () =>
  createSelector(
    selectAll,
    (entities: Solution[], { portfolioId }: { portfolioId: string }) =>
      entities.filter((item) => item.portfolioId === portfolioId)
    // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
    // return filteredEntities;
  )
export const getSolutionsByPortfolioIdParam = createSelector(
  selectAll,
  (entities: Solution[]) => (portfolioId: string) =>
    entities.filter((item) => item.portfolioId === portfolioId)
  // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
  // return filteredEntities;
)
export const getDeliveredSolutionsByPortfolioId = createSelector(
  selectAll,
  (entities: Solution[], { portfolioId }: { portfolioId: string }) =>
    entities.filter(
      (item) =>
        item.portfolioId === portfolioId && item?.customerType === 'external'
    )
  // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
  // return filteredEntities;
)
export const getAvailableSolutionsByPortfolioId = createSelector(
  selectAll,
  (entities: Solution[], { portfolioId }: { portfolioId: string }) =>
    entities.filter(
      (item) =>
        item.portfolioId === portfolioId &&
        (item.belongsToArt === undefined ||
          item.belongsToArt === null ||
          item.belongsToArt === false)
    )
  // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
  // return filteredEntities;
)
export const getSolutionsByValueStreamId = (valueStreamId: string) =>
  createSelector(
    selectAll,
    (entities: Solution[]) =>
      entities.filter((item) => item.valueStreamId === valueStreamId)
    // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
    // return filteredEntities;
  )
export const getSolutionsByValueStreamIdFilterByType = (
  valueStreamId: string
) =>
  createSelector(
    selectAll,
    (entities: Solution[]) =>
      entities.filter(
        (item) =>
          item.valueStreamId === valueStreamId &&
          item.customerType === 'external'
      )
    // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
    // return filteredEntities;
  )
export const getSolutionsByPortfolioIdwithNoValueStreamId = createSelector(
  selectAll,
  (entities: Solution[], { portfolioId }: { portfolioId: string }) =>
    entities.filter(
      (item) =>
        item.portfolioId === portfolioId &&
        (item.valueStreamId === undefined || item.valueStreamId === null)
    )
  // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
  // return filteredEntities;
)
export const getSolutionsByOrganizationwithNoValueStreamId = createSelector(
  selectAll,
  (entities: Solution[], { organizationId }: { organizationId: string }) =>
    entities.filter(
      (item) =>
        item.organizationId === organizationId &&
        item.customerType === 'external' &&
        (item.valueStreamId === undefined || item.valueStreamId === null)
    )
  // const filteredEntities = entities.filter(item => item[columnId] === columnValue);
  // return filteredEntities;
)

export const getRelatedSolutionsBySolutionId = (id: string) =>
  createSelector(selectEntities, (entities: Dictionary<Solution>) => {
    const solution: Solution = entities[id]

    if (solution && solution.relations) {
      const linkedSolutions: LinkedSolution[] = []
      solution.relations.forEach((relation) => {
        const ent = entities[relation.relatedSolutionId]
        if (ent !== undefined) {
          const linkedList: LinkedSolution = {
            solution: { ...ent },
            relationType: relation.relationType,
            ownerId: solution.id,
            relationId: relation.relationId
          }
          linkedSolutions.push(linkedList)
        }
      })

      return linkedSolutions
    } else {
      return []
    }
  })

/*

export const getEpicsByColumnId = createSelector(
  selectAll,
  (entities: Epic[], { columnId }: { columnId: string }) =>
    entities.filter(epic => epic.columnId === columnId)
);

export const getSolutionById = () => {
  return createSelector(
    selectAllSolutions,
    (entity:Solution[], props: { id:string }) => {
      return entity[props.id];
    },
  );

};*/

// const { selectEntities, selectAll } = solutionAdapter.getSelectors();

/*
export const selectSolutionsState = createSelector(
  selectExamples,
  (state: SolutionState) => state.solutions
);


export const selectAllSolutions = createSelector(selectSolutionsState, selectAll);
export const selectSolutionsEntities = createSelector(selectSolutionsState, selectEntities);

export const selectSelectedSolution = createSelector(
  selectSolutionsEntities,
  selectRouterState,
  (entities, params) => params && entities[params.state.params.id]
);

export const selectAllBooks = (state: AppState) => state.allBooks;




const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectSolutionsEntities = createSelector(selectSolutionsState, selectEntities);
*/
export const selectSolutionId = selectRouteParam('id')

export const selectSelectedSolution = createSelector(
  selectEntities,
  selectCurrentRoute,
  (entities, params) => {
    if (params.routeConfig.path.indexOf('solution/:id') > -1)
      return entities[params.params.id]
    else return
  }
)

/*
export const selectSelectedSolution = createSelector(
  selectEntities,
  selectRouterState,
  (entities, params) => params && entities[params.state.params.id]
);
*/
/**
 *  @deprecated this method should not be used
 */
export const getSolutionsByValueStreamIdFilterByTypeInternal = (
  valueStreamId: string
) =>
  createSelector(selectAll, (entities: Solution[]) =>
    entities.filter(
      (item) =>
        item.valueStreamId === valueStreamId && item.customerType === 'internal'
    )
  )

export const getSolutionsByOrganizationIdFilterByTypeInternal = (
  organizationId: string
) =>
  createSelector(selectAll, (entities: Solution[]) => {
    const solutions = entities.filter(
      (item) =>
        item.organizationId === organizationId &&
        item.customerType === 'internal'
    )

    return solutions
  })

export const getSolutionsByOrganizationIdFilterByTypeExternal = (
  organizationId: string
) =>
  createSelector(selectAll, (entities: Solution[]) =>
    entities.filter(
      (item) =>
        item.organizationId === organizationId &&
        item.customerType === 'external'
    )
  )

export function getDevelopingValueStreamsByPortfolioIdWithSolutions(
  portfolioId: any
): (state: object) => unknown {
  throw new Error('Function not implemented.')
}
