import {
  Component,
  OnInit,
  Inject,
  ChangeDetectionStrategy
} from '@angular/core'
import * as fromOpenAIActions from '../../../features/openai/open-ai.actions'
import { Observable, Subscription } from 'rxjs'
import { FormBuilder, FormGroup } from '@angular/forms'
import { UserStoryFormComponent } from '../../requirement-forms/user-story-form/user-story-form.component'
import {
  GenerateAcceptanceCriteriaRequest,
  OpenAIStore
} from '../../../features/openai/open-ai.model'
import { OverlayFormRef } from '../../../overlay-forms/overlay/overlay-form-ref'
import { INIT_DATA1 } from '../../../overlay-forms/overlay/overlay.service'

import { Store } from '@ngrx/store'

@Component({
  selector: 'anms-story-acceptance-criteria-form',
  templateUrl: './story-acceptance-criteria-form.component.html',
  styleUrls: ['./story-acceptance-criteria-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoryAcceptanceCriteriaFormComponent implements OnInit {
  form: FormGroup

  parentComponent: UserStoryFormComponent

  descriptions$: Observable<string[]>

  subscription: Subscription

  description
  itemName
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['blockquote', 'code-block']
    ]
  }

  constructor(
    private formBuilder: FormBuilder,
    private readonly openAIStore: OpenAIStore,
    private store: Store,
    private dialogRef: OverlayFormRef,
    @Inject(INIT_DATA1)
    public data: {
      itemName
      description
      parentComponent
      acceptanceCriteria
    }
  ) {
    this.form = this.formBuilder.group({
      acceptanceCriteria: '',
      improvedAcceptanceCriteria: ''
    })
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  ngOnInit(): void {
    this.description = this.data.description
    this.itemName = this.data.itemName

    this.parentComponent = this.data.parentComponent as UserStoryFormComponent

    this.descriptions$ = this.openAIStore.filterItemsByType<string>(
      'acceptance'
    )
    this.descriptions$.subscribe((val) => console.log(val))
    this.subscription = this.descriptions$.subscribe((val) => {
      if (val && val.length > 0) {
        console.log('heere')
        const description = val[0]
        if (description) {
          const filteredDescription = description
          console.log(filteredDescription)
          this.form
            .get('improvedAcceptanceCriteria')
            .setValue(filteredDescription)
        }
      }
    })
  }
  onSubmit(buttonType): void {
    if (buttonType === 'load') {
      this.generateAcceptanceCriteria()
    }
  }

  generateAcceptanceCriteria() {
    if (this.form.valid) {
      const req: GenerateAcceptanceCriteriaRequest = {
        description: this.description,
        itemName: this.itemName,
        n: 1
      }
      this.store.dispatch(
        fromOpenAIActions.generateAcceptanceCriteria({ data: req })
      )
    }
  }

  moveTextToLeft(text: string): void {
    this.form.get('acceptanceCriteria').setValue(text)
  }

  copyContentToParent() {
    const acceptanceCriteria = this.form.get('acceptanceCriteria').value
    // this.dialogRef.
    this.parentComponent.setAcceptanceCriteria(acceptanceCriteria)
    this.dialogRef.close()
  }
  closeDialog() {
    this.dialogRef.close()
  }
}
