import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  OnDestroy,
  ViewChild
} from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromOpenAIActions from '../../../features/openai/open-ai.actions'
import { OverlayFormRef } from '../../../overlay-forms/overlay/overlay-form-ref'
import { INIT_DATA1 } from '../../../overlay-forms/overlay/overlay.service'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import {
  GenerateAcceptanceCriteriaRequest,
  GenerateFeatureAcceptanceCriteriaRequest,
  OpenAIStore
} from '../../../features/openai/open-ai.model'
import { Observable, Subscription } from 'rxjs'
import { FeatureFormComponent } from '../../requirement-forms/feature/feature-form/feature-form.component'
import { ContentChange, QuillEditorComponent } from 'ngx-quill'
import { MatQuill } from '../../../mat-quill/mat-quill'

@Component({
  selector: 'anms-acceptance-criteria-form',
  templateUrl: './acceptance-criteria-form.component.html',
  styleUrls: ['./acceptance-criteria-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcceptanceCriteriaFormComponent implements OnInit, OnDestroy {
  @ViewChild('matEditor', {
    static: true
  })
  matEditor: MatQuill

  form: FormGroup
  parentComponent: FeatureFormComponent
  descriptions$: Observable<string[]>
  subscription: Subscription
  description: string
  benefitHypothesis: string

  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['blockquote', 'code-block']
    ]
  }

  constructor(
    private formBuilder: FormBuilder,
    private readonly openAIStore: OpenAIStore,
    private store: Store,
    private dialogRef: OverlayFormRef,
    @Inject(INIT_DATA1)
    public data: {
      description: string
      parentComponent: FeatureFormComponent
      acceptanceCriteria: string
      benefitHypothesis: string
    }
  ) {
    this.form = this.formBuilder.group({
      acceptanceCriteria: '',
      improvedAcceptanceCriteria: ''
    })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  ngOnInit(): void {
    this.description = this.data.description
    this.benefitHypothesis = this.data.benefitHypothesis
    this.parentComponent = this.data.parentComponent

    this.descriptions$ = this.openAIStore.filterItemsByType<string>(
      'acceptance'
    )
    this.subscription = this.descriptions$.subscribe((val) => {
      if (val && val.length > 0) {
        const description = val[0]
        if (description) {
          this.form.get('improvedAcceptanceCriteria').setValue(description)
        }
      }
    })
  }

  onSubmit(buttonType: string): void {
    if (buttonType === 'load') {
      this.generateAcceptanceCriteria()
    }
  }

  generateAcceptanceCriteria(): void {
    if (this.form.valid) {
      const req: GenerateFeatureAcceptanceCriteriaRequest = {
        description: this.description,
        benefitHypothesis: this.benefitHypothesis,
        n: 1
      }
      this.store.dispatch(
        fromOpenAIActions.generateFeatureAcceptanceCriteria({ data: req })
      )
    }
  }

  moveTextToLeft(text: string): void {
    this.form.get('acceptanceCriteria').setValue(text)
  }

  copyContentToParent(): void {
    const acceptanceCriteria = this.form.get('acceptanceCriteria').value
    this.parentComponent.setAcceptanceCriteria(acceptanceCriteria)
    this.dialogRef.close()
  }

  closeDialog(): void {
    this.dialogRef.close()
  }
}
